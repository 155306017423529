import { ButtonSize } from "@components/Button";
import { SocialLoginButton } from "@components/SocialLoginButton";
import type { CSSInterpolation } from "@emotion/serialize";
import React from "react";

import { IdentityProvider } from "@every.org/common/src/codecs/auth";

export const SocialLoginButtons: React.FCC<{
  redirectUrl?: string;
  buttonSize?: ButtonSize;
  buttonCss?: CSSInterpolation;
  wrapperCss?: CSSInterpolation;
  skipWelcomeModal?: boolean;
  condensedSignUpFlow?: boolean;
  longText?: boolean;
  showFacebook?: boolean;
}> = ({
  redirectUrl,
  buttonSize,
  buttonCss,
  wrapperCss,
  skipWelcomeModal,
  condensedSignUpFlow,
  longText,
  showFacebook = false,
}) => {
  return (
    <div css={wrapperCss}>
      <SocialLoginButton
        idp={IdentityProvider.GOOGLE}
        data-tname="signupGoogle"
        redirectUrl={redirectUrl}
        size={buttonSize}
        css={buttonCss}
        skipWelcomeModal={skipWelcomeModal}
        condensedSignUpFlow={condensedSignUpFlow}
      >
        {!longText && "Google"}
      </SocialLoginButton>
      {showFacebook && (
        <SocialLoginButton
          idp={IdentityProvider.FACEBOOK}
          data-tname="signupFacebook"
          redirectUrl={redirectUrl}
          size={buttonSize}
          css={buttonCss}
          skipWelcomeModal={skipWelcomeModal}
          condensedSignUpFlow={condensedSignUpFlow}
        >
          {!longText && "Facebook"}
        </SocialLoginButton>
      )}
    </div>
  );
};
